var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"color":"primary","to":{ name: 'TeachingTool Create' }}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_teaching_tool'))+" ")]),_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"color":"primary","to":_vm.getLastRouteLogisticSession}},[_vm._v(" "+_vm._s(_vm.$t('btn.return'))+" ")])],1)],2),_c('v-container',{attrs:{"id":"teaching_tool_associate_logistic_session_list-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-clipboard-text"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t( 'teaching_tool_associate_logistic_session_list.list.title' ))+" ")])])]},proxy:true}])},[_c('v-row',[_c('v-col',{attrs:{"md":"9"}}),_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.associateCheckedTeachingToolsToLogisticSession()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.associate_teaching_tools'))+" ")])],1)])],1),_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.headers,"items":_vm.associateTeachingToolsList,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedTeachingTools),expression:"checkedTeachingTools"}],attrs:{"type":"checkbox"},domProps:{"value":item['@id'],"checked":Array.isArray(_vm.checkedTeachingTools)?_vm._i(_vm.checkedTeachingTools,item['@id'])>-1:(_vm.checkedTeachingTools)},on:{"change":function($event){var $$a=_vm.checkedTeachingTools,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item['@id'],$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedTeachingTools=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedTeachingTools=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedTeachingTools=$$c}}}})])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"dark":_vm.$vuetify.theme.dark,"bottom":"","origin":"center center","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"filter-header",attrs:{"small":"","color":_vm.filters.nameSearched ? 'primary' : 'secondary'}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}],null,true)},[_c('v-card',{staticClass:"px-5",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","outlined":""}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"item-value":"@id","type":"text","label":_vm.$t(
                        'teaching_tool_associate_logistic_session_list.list.filter.name'
                      )},model:{value:(_vm.filters.nameSearched),callback:function ($$v) {_vm.$set(_vm.filters, "nameSearched", $$v)},expression:"filters.nameSearched"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary","disabled":_vm.filters.nameSearched.length === 0},on:{"click":function($event){_vm.filters.nameSearched = ''}}},[_vm._v(" "+_vm._s(_vm.$t('list.delete_search_term'))+" ")])],1)],1)]}},{key:"header.reference",fn:function(ref){
                      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"dark":_vm.$vuetify.theme.dark,"bottom":"","origin":"center center","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"filter-header",attrs:{"small":"","color":_vm.filters.nameSearched ? 'primary' : 'secondary'}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}],null,true)},[_c('v-card',{staticClass:"px-5",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","outlined":""}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":_vm.$t(
                        'teaching_tool_associate_logistic_session_list.list.filter.reference'
                      )},model:{value:(_vm.filters.referenceSearched),callback:function ($$v) {_vm.$set(_vm.filters, "referenceSearched", $$v)},expression:"filters.referenceSearched"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary","disabled":_vm.filters.referenceSearched.length === 0},on:{"click":function($event){_vm.filters.referenceSearched = ''}}},[_vm._v(" "+_vm._s(_vm.$t('list.delete_search_term'))+" ")])],1)],1)]}},{key:"header.area.name",fn:function(ref){
                      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"dark":_vm.$vuetify.theme.dark,"bottom":"","origin":"center center","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"filter-header",attrs:{"small":"","color":_vm.filters.nameSearched ? 'primary' : 'secondary'}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}],null,true)},[_c('v-card',{staticClass:"px-5",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","outlined":""}},[_c('v-select',{staticClass:"pa-4",attrs:{"items":_vm.listAreas,"item-value":"@id","item-text":function (item) { return _vm.$t('form.area', {
                      name: item.name,
                    }); },"type":"text","label":_vm.$t(
                        'teaching_tool_associate_logistic_session_list.list.filter.area'
                      )},model:{value:(_vm.filters.areaSearched),callback:function ($$v) {_vm.$set(_vm.filters, "areaSearched", $$v)},expression:"filters.areaSearched"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary","disabled":_vm.filters.areaSearched.length === 0},on:{"click":function($event){_vm.filters.areaSearched = ''}}},[_vm._v(" "+_vm._s(_vm.$t('list.delete_search_term'))+" ")])],1)],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }